import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import benefitsFile from '../files/il-employment-quality-benefits-poster.pdf'
import jobSecurityFile from '../files/il-employment-quality-job-security-poster.pdf'
import payFile from '../files/il-employment-quality-pay-poster.pdf'

export default function FindingsPage () {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "cooks-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      pieImage: file(relativePath: { eq: "findings-pie.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      payCoverImage: file(relativePath: { eq: "pay-poster.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      benefitsCoverImage: file(relativePath: { eq: "benefits-poster.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      jobSecurityCoverImage: file(relativePath: { eq: "job-security-poster.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      site {
        siteMetadata {
          siteTitle
          siteDescription
          author
          siteUrl
          siteImage
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='Findings' data={data.site} />
      <Img fluid={data.bannerImage.childImageSharp.fluid} style={{ maxHeight: '450px' }} alt='findings banner image' />
      <div className='container'>
        <section className='my-4 container'>
          <h1 className='text-center'>Findings</h1>
          
          <p className='lead'>Researchers with the Project for Middle Class Renewal (PMCR) conducted a study to help solve the puzzle of employment quality. We started by asking over 3,500 workers across Illinois to rate their employment situation and also answer questions about their pay, benefits, scheduling, outlook, and experiences on the job. We then analyzed which job features are associated with higher or lower average ratings. This research shows employment quality has multiple dimensions that fit together to make jobs better or worse.</p>
          
          <p className='lead'>Decent pay and benefits are the most obvious dimensions of employment quality, but not necessarily the most important for how workers rate their jobs. On the one hand, problems of low pay and inadequate benefits are made worse by the threat of job loss, unreliable hours, and limited opportunities for training or promotion. On the other hand, workers who find meaning, freedom, and social support at work tend to view their situation more favorably than workers who make more money but do not find their jobs intrinsically rewarding.</p>
          
          <p className='lead'>The figure below illustrates the most important pieces of the employment quality puzzle according to our study. The “big three” pieces of employment quality have to do with job content, work environment, and outlook. For example, how secure workers feel is a big part of their outlook for the future and their overall quality of employment. The more secure workers feel, the higher they rate their employment situation. For this reason, unions or legal protections against arbitrary termination can go a long way toward improving the quality of employment in Illinois.</p>
        
          <div className='d-flex justify-content-center m-2'>
            <Img fluid={data.pieImage.childImageSharp.fluid} style={{ width: '450px', height: '450px' }} />
          </div>
          
          <p className='lead'>Our analysis also highlights that efforts to advance social, gender, and racial equity in the workplace must consider how various vulnerable and socially marginalized groups disproportionately experience poor employment quality along multiple dimensions. Women and workers of color are more likely to occupy lower-paid jobs that also expose them to poor health and safety conditions and offer fewer opportunities for promotion. Our findings reinforce other research showing that job segregation by gender and race are important drivers of labor market inequality. Therefore, raising the floor of employment quality across all industries and occupations and expanding access to high quality jobs to those who have historically been underrepresented are key to advance racial and gender equity in the labor force.</p>
        
          <p className='lead'><strong>Download our full report: "<a href='https://lep.illinois.edu/wp-content/uploads/2022/09/A-Good-Job-Not-Just-Any-Job-9_1_22.pdf' target='__blank'>A Good Job, Not Just Any Job</a>."</strong></p>
        </section>
        
        <section class='my-4 container'>
          <h3>Download our fact sheets</h3>
          <p className='lead'>Find more results from our study in the fact sheets below. Each fact sheet focuses on a different component of employment quality and its policy implications. Learn why a higher minimum wage isn’t enough to solve the problem of low pay, how unions improve employment quality, and what government can do to expand employment protections and benefits to more workers in Illinois.</p>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <a href={payFile} download className='text-center dark-link'>
                <Img fluid={data.payCoverImage.childImageSharp.fluid} style={{ width: '300px' }} />
                Pay & scheduling
              </a>
            </div>
            
            <div className='col d-flex justify-content-center'>
              <a href={jobSecurityFile} download className='text-center dark-link'>
                <Img fluid={data.jobSecurityCoverImage.childImageSharp.fluid} style={{ width: '300px' }} />
                Job security & opportunity
              </a>
            </div>
            
            <div className='col d-flex justify-content-center'>
              <a href={benefitsFile} download className='text-center dark-link'>
                <Img fluid={data.benefitsCoverImage.childImageSharp.fluid} style={{ width: '300px' }} />
                Benefits & supports
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}